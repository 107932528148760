<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title>{{ $t("set or reset password") }}</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear :active="loading" indeterminate />
        <validation-observer
          ref="obs"
          v-slot="{ errors, invalid, validated, passes, validate }"
        >
          <v-card-text>
            <validation-provider
              vid="customer"
              :name="$t('username')"
              rules="required"
              v-slot="{ errors, valid }"
            >
              <v-text-field
                :label="$t('username')"
                name="customer"
                prepend-icon="mdi-account"
                type="text"
                v-model="customer"
                :error-messages="errors"
                :success="valid"
                :clearable="!isMobile"
                required
                :hint="$t('usernameHint')"
              />
            </validation-provider>
            <validation-provider
              vid="token"
              :name="$t('two factor token')"
              :rules="twoFactorEnabled ? 'required|digits:6' : ''"
              v-slot="{ errors, valid }"
            >
              <v-text-field
                v-if="twoFactorEnabled"
                id="token"
                :label="$t('two factor token')"
                name="email"
                prepend-icon="mdi-two-factor-authentication"
                type="text"
                v-model="token"
                :error-messages="errors"
                :success="valid"
                :required="twoFactorEnabled ? true : false"
                :clearable="!isMobile"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              name="goBack"
              :disabled="loading"
              @click="$router.go(-1)"
              text
              >{{ $t("go back") }}</v-btn
            >
            <v-btn
              name="resetPassword"
              color="primary"
              :disabled="loading || invalid"
              @click="resetPassword()"
              >{{ $t("reset") }}</v-btn
            >
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "PasswordReset",
  mixins: [showErrors, isMobile],
  props: {
    username: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    twoFactorEnabled: false,
    customer: "",
    token: "",
  }),
  computed: {
    tokenRues() {
      rules = [""];
    },
  },
  watch: {
    username: {
      immediate: true,
      handler(value) {
        this.customer = value;
      },
    },
  },
  methods: {
    resetPassword: function() {
      var that = this;
      this.loading = true;
      var params = { customer: this.customer };
      if (this.token) params["token"] = this.token;
      this.$http
        .post("login/reset_password", params)
        .then(function(response) {
          that.$snotify.confirm(
            that.$i18n.t("{response}. Please check your email inbox.", {
              response: response.data.detail[0],
            }),
            {
              showProgressBar: false,
              closeOnClick: false,
              buttons: [
                {
                  text: "OK",
                  action: (toast) => {
                    that.$snotify.remove(toast.id);
                  },
                  bold: true,
                },
              ],
            }
          );
          that.$router.push({ name: "login" });
        })
        .catch(function(error) {
          if (error.unauthorized()) {
            // 401: means 2FA is enabled and token required
            that.twoFactorEnabled = true;
          } else {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "set or reset password": "Set or reset password",
    "username": "Customer number",
    "two factor token": "Two factor token",
    "reset": "Reset",
    "go back": "Go back",
    "{response}. Please check your email inbox.": "{response}. Please check your email inbox.",
    "usernameHint": "<customer number> or <customer number:email>"
  },
  "de": {
    "set or reset password": "Passwort setzen oder zurücksetzen",
    "username": "Kundennummer",
    "two factor token": "Zwei Faktor PIN",
    "reset": "zurücksetzen",
    "go back": "zurück",
    "{response}. Please check your email inbox.": "{response}. Bitte überprüfen Sie Ihren Email Eingang.",
    "usernameHint": "<Kundennummer> oder <Kundennummer:emailadresse>"
  }
}
</i18n>
